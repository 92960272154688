<template>
  <sub-page
      :title="$t('Projects')"
      :header-action="openAddProject"
      :header-action-text="$t('projects.add')"
      :no-content-condition="items.length === 0"
      no-content-text="projects.no_content"
      no-content-button-text="projects.add"
      :no-content-button-action="openAddProject"
      :header-action-condition="items.length > 0"
      fill-height
      v-model="$store.state.settings.pageData"
  >
    <template #default>
      <ws-data-table
          class="mt-5"
          :items="items"
          :headers="headers"
          :row-action="($event) => $router.push(businessDashLink(`project-manager/projects/${$event.uuid}`))"
      >

        <template #item.date_created="{item}">
          <h5> {{ MONTH_DAY_TIME(item.date_created , false)  }}</h5>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <ws-dialog
          v-if="displayDialog"
          v-model="displayDialog"
          @save="addEditProject"
          :title="newEntity ? $t('projects.add') : $t('projects.edit') "
      >

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

        <ws-date-picker
            v-model="entityData.date_end"
            :label="$t('DateEnd')"
            class="mt-5"
        />

      </ws-dialog>

    </template>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Projects",
  data() {
    return {
      items : [],
      displayDialog : false,
      newEntity : false,
      entityData : {}
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Participants') , value : 'participants_count' , width : 5 },
        { text : this.$t('DateCreated') , value : 'date_created' , width : 5 },
      ]
    }
  },
  methods : {

    ...mapActions('project_manager', [
      'GET_PROJECTS' ,
      'ADD_EDIT_PROJECT',
    ]),

    openAddProject() {
      this.newEntity = true
      this.entityData = {}
      this.displayDialog = true
    },
    async addEditProject() {

      let result = await this.ADD_EDIT_PROJECT(this.entityData)
      if ( !result ) {
        return this.$t('NetworkError')
      }

      if ( this.newEntity ) {
        this.items.push(result)
      }

      this.displayDialog = false
      this.notify(this.$t('projects.created'))

    },

    async initPage() {
      let result = await this.GET_PROJECTS()
      if ( !result ) {
        return this.$t('NetworkError')
      }
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>